<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span v-for="(field, index) in fields" :key="index" >
                  <v-text-field v-if="field.resourcetype === 'String'"
                                :label="field.text"
                                type="text"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="actual_data[field.value]"
                                @change="actual_data[field.value] = $event"
                                :required="!field.empty"></v-text-field>
                  <v-text-field v-if="field.resourcetype === 'Integer'"
                                :label="field.text"
                                type="number"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="actual_data[field.value]"
                                @change="actual_data[field.value] = $event"
                                :required="!field.empty"></v-text-field>
                  <v-text-field v-if="field.resourcetype === 'Float'"
                                :label="field.text"
                                type="number"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="actual_data[field.value]"
                                @change="actual_data[field.value] = $event"
                                :required="!field.empty"></v-text-field>
                  <v-autocomplete v-if="field.resourcetype === 'Choice'"
                                  :items="field.possible_values"
                                  :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                  :label="field.text"
                                  v-model="actual_data[field.value]"
                                  :multiple="field.multiple"
                                  :required="!field.empty"
                                  @change="actual_data[field.value] = $event"
                  ></v-autocomplete>
                  <v-radio-group v-if="field.resourcetype === 'Boolean'"
                                  :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                  :label="field.text"
                                  v-model="actual_data[field.value]"
                                  @change="actual_data[field.value] = $event"
                                  :required="!field.empty">
                      <v-radio :label="field.true_repr" value=1></v-radio>
                      <v-radio :label="field.false_repr" value=0></v-radio>
                  </v-radio-group>
                  <span v-if ="field.resourcetype === 'FileUpload'">{{ field.text }}
                    <input type="file" name="file" :key="filekey" :multiple="field.files === 'multiple'" @change="handleFileUpload( $event, field.value )"><br>
                  </span>
                </span>
                <v-btn @click="clear()">Clear</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('Close')">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  mounted() {
    this.fileData = new FormData()
  },
  name: "CreateForm",
  methods: {
    clear() {
      this.actual_data = {}
      this.fileData = new FormData()
      this.filekey++
    },
    handleFileUpload( event, field_value ){
      this.files = []
      for (let i = 0; i < event.target.files.length; i++) {
        this.files.push([event.target.files[i], event.target.files[i].name, field_value])
      }
    },
    save() {
      this.fileData.set('data', JSON.stringify(this.actual_data))
      this.fileData.set('model', this.model_id)
      this.fileData.set('name', 'test')
      for (let i = 0; i < this.files.length; i++) {
        this.fileData.append('file_field_' + this.files[i][2], this.files[i][0])
      }
      axios.post(process.env.VUE_APP_API_URL + "/api/freemodelitems/",
      this.fileData,{
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if (response.data.detail === "Created") {
          this.fileData = new FormData();
          this.actual_data = {};
          this.filekey++;
          this.files = []
          this.response = {};
          this.$emit('Close');
        }
      })
      .catch((error) => {
        this.response = error.response.data;
       }
      )
    }
  },
  props: ['dialog', 'title', 'model_id', 'fields', 'token'],
  data() {
    return {
      fileData: {},
      actual_data: {},
      files: [],
      filekey: 0,
      response: {}
    }
  }
}
</script>

<style scoped>

</style>
