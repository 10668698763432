<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogDelete"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h4">
          Are you sure?
        </v-card-title>
        <v-card-text>You are about to delete {{ selected.length }} items.
          <br>This action cannot be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="$emit('Close')"
          >
            No
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="delete_selected()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteForm",
  props: ['dialogDelete', 'selected', 'modelCaption', "token"],
  methods: {
    delete_selected() {
      console.log("HIER")
      axios.post(process.env.VUE_APP_API_URL + "/api/freemodelitems/delete/",
          {"selected": this.selected},
          { headers: {'Authorization': 'Bearer ' + this.token}})
          .then(() => {
            this.$emit('CloseSuccess')
          })
    }
  }
}
</script>

<style scoped>

</style>
