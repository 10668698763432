<template>
  <div>
<span v-for="(related_model, index) in items"
      :key="'pm'+index"
      @click="$emit('setModel', related_model.id, item_id, c_or_p)">
          <b><i>
           {{ related_model.caption }}
          </i></b>
        </span>
    </div>
</template>

<script>
export default {
  name: "RelatedModels",
  props: ["items", "item_id", "c_or_p"]
}
</script>

<style scoped>
span b {
  cursor: pointer;
}
</style>