<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
              <span v-if="selected !== undefined">
                <span v-for="(field, index) in fields" :key="index" >
                  <v-text-field v-if="field.resourcetype === 'String'"
                                :label="field.text"
                                type="text"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="selected[field.value]"
                                :required="!field.empty"></v-text-field>
                  <h4 v-if="field.resourcetype === 'DateField'">{{ field.text }}</h4>
                  <v-date-picker v-if="field.resourcetype === 'DateField'"
                                no-title
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="selected[field.value]"
                                :required="!field.empty"
                  ></v-date-picker>
                  <br v-if="field.resourcetype === 'DateField'">
                  <br v-if="field.resourcetype === 'DateField'">

                  <h4 v-if="field.resourcetype === 'TimeField'">{{ field.text }}</h4>
                  <v-time-picker v-if="field.resourcetype === 'TimeField'"
                                :title="field.text"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="selected[field.value]"
                                :required="!field.empty"
                                 format="24"
                  ></v-time-picker><br v-if="field.resourcetype === 'TimeField'">
                  <v-text-field v-if="field.resourcetype === 'Integer'"
                                :label="field.text"
                                type="number"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                v-model="selected[field.value]"
                                :required="!field.empty"></v-text-field>
                  <v-text-field v-if="field.resourcetype === 'Float'"
                                :label="field.text"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                type="number"
                                v-model="selected[field.value]"
                                :required="!field.empty"></v-text-field>
                  <v-textarea v-if="field.resourcetype === 'TextArea'"
                                :label="field.text"
                                :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                type="text"
                                v-model="selected[field.value]"
                                :required="!field.empty"></v-textarea>
                  <v-autocomplete v-if="field.resourcetype === 'Choice'"
                                  :items="field.possible_values"
                                  :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                  :label="field.text"
                                  v-model="selected[field.value]"
                                  :multiple="field.multiple"
                                  :required="!field.empty"
                                  @change="actual_data[field.value] = $event">
                  </v-autocomplete>
                  <v-radio-group v-if="field.resourcetype === 'Boolean'"
                                  :error-messages="(response[field.value] !== undefined) ? response[field.value] : ''"
                                  :label="field.text"
                                  v-model="selected[field.value]"
                                  @change="actual_data[field.value] = $event"
                                  :required="!field.empty">
                      <v-radio :label="field.true_repr" v-bind:value="true"></v-radio>
                      <v-radio :label="field.false_repr" v-bind:value="false"></v-radio>
                  </v-radio-group>
                  <span v-if="field.resourcetype === 'FileUpload'">{{ field.text }}
                    <input type="file" name="file" :key="filekey" :multiple="field.files === 'multiple'" @change="handleFileUpload( $event, field.value )">
                    <span v-if="field.files === 'multiple'">
                      <v-radio-group
                        column
                        mandatory
                        @change="file_action = $event">
                          <v-radio label="Add to existing" value="add"></v-radio>
                          <v-radio label="Remove and replace" value="remove_replace"></v-radio>
                          <v-radio label="Unlink and replace" value="unlink_replace"></v-radio>
                      </v-radio-group>
                    </span>
                  </span>
                </span>
              </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('Close')">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  mounted() {
    this.fileData = new FormData()
  },
  name: "EditForm",
  methods: {
    handleFileUpload( event, field_value ){
      if (this.selected !== undefined) {
        this.files = []
        for (let i = 0; i < event.target.files.length; i++) {
          this.files.push([event.target.files[i], event.target.files[i].name, field_value])
        }
      }
    },
    save() {
      let data = {}
      for (let i = 0; i < this.fields.length; i++) {
        data[this.fields[i].value] = this.selected[this.fields[i].value]
      }
      this.fileData.set('data', JSON.stringify(data))
      this.fileData.set('model', this.model_id)
      this.fileData.set('name', 'test')
      this.fileData.set('file_action', this.file_action)
      for (let i = 0; i < this.files.length; i++) {
        this.fileData.append('file_field_' + this.files[i][2], this.files[i][0])
      }
      axios.patch(process.env.VUE_APP_API_URL + "/api/freemodelitems/" + this.selected.id + "/",
          this.fileData,{
						headers: {
								'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.token
						}
					})
          .then((response) => {
            if (response.data.detail === "Updated") {
              this.fileData = new FormData();
              this.actual_data = {};
              this.filekey++;
              this.files = [];
              this.response = {};
              this.$emit('Close');
            }
          })
          .catch((error) => {
            this.response = error.response.data;
           }
          )
    }
  },
  props: ['dialog', 'title', 'model_id', 'fields', 'selected', 'token'],
  data() {
    return {
      fileData: {},
      actual_data: {},
      files: [],
      filekey: 0,
      response: {},
      file_action: 'add'
    }
  }
}
</script>
<style scoped>
</style>
