<template>
  <div>
  <span v-for="(single_relation, index) in items"
        :key="'sp'+index"
        @click="$emit('setModel', single_relation.model_id, single_relation.id)">
          <b><i>
            {{ single_relation.model_name }}: {{ single_relation.name }}
          </i></b> |
        </span>
  </div>
</template>

<script>
export default {
  name: "SingleRelations",
  props: ["items"]
}
</script>

<style scoped>
span b {
  cursor: pointer;
}
</style>