<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Graph</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <line-chart ref="lineChartComponent" :chart-data="chartData" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('Close')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Line } from "vue-chartjs";
export default {
  name: "Graph",
  data() {
    return {
      rawChartData: {}
    };
  },
  computed: {
    chartData() {
      // Return a copy of the raw chart data to force reactivity
      return JSON.parse(JSON.stringify(this.rawChartData));
    }
  },
  methods: {
    async fetchData() {
        this.rawChartData = {
        labels: [], // X-axis labels (time)
        datasets: [
          {
            label: "Temperature Local",
            data: [], // Y-axis data (temperature)
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1
          },
          {
            label: "Temperature Remote",
            data: [], // Y-axis data (temperature)
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1
          }
        ]
      }
        let pushed = [];
        this.selected.forEach((result) => {
          if (pushed.indexOf(result.time) === -1) {
            this.chartData.labels.push(result.time);
            pushed.push(result.time);
          }
          if (result.name==="LOCAL") {
            this.chartData.datasets[0].data.push(result.temp);
          }
          else if (result.name==="REMOTE") {
            this.chartData.datasets[1].data.push(result.temp);
          }
        });
    }
  },
  components: {
    LineChart: {
      extends: Line,
      props: ["chartData"]
    }
  },
  props: ["dialog", "selected"],
  watch: {
    dialog() {
    //if (newVal) {
      console.log(this.selected)
      this.fetchData();
      setTimeout(() => {
        const lineChartComponent = this.$refs.lineChartComponent;
        if (lineChartComponent) {
          lineChartComponent.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false
          });
        }
      }, 0);
    //}
  }
  }
};

</script>

<style>
/* Add your styles here */
</style>
