<template>
  <v-col cols="12" sm="3">
    <b>Total:</b> {{ total_items }}
    <b>Selected:</b> {{ selected.length }}
  </v-col>
</template>
<script>
export default {
  name: "Summary",
  props: [
    "total_items",
    "selected"
  ]
}
</script>
