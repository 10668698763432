<template>
  <v-dialog v-model="showModal" max-width="750px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <video v-if="isVideo" ref="mediaPlayer" controls :src="fileUrl" height="450" width="650" :loop="isLoopActive" @ended="handleEnded">
          Your browser does not support the video tag.
        </video>
        <audio v-else-if="isAudio" ref="mediaPlayer" controls :src="fileUrl" :loop="isLoopActive" @ended="handleEnded">
          Your browser does not support the audio element.
        </audio>
        <div v-else-if="isImage" class="image-container">
          <img :src="fileUrl" alt="Media content" class="responsive-image">
        </div>
      </v-card-text>
      <v-card-actions>

        <!-- Previous Button -->
        <v-btn @click="previous">
          <v-icon left>mdi-rewind</v-icon>
        </v-btn>

        <!-- Next Button -->
        <v-btn @click="next">
          <v-icon left>mdi-fast-forward</v-icon>
        </v-btn>

        <!-- Control Buttons -->
        <v-btn icon @click="skipForward" v-bind:disabled="isImage">
          <v-icon>mdi-fast-forward-30</v-icon>
        </v-btn>
        <v-btn icon @click="skipBackward" v-bind:disabled="isImage">
          <v-icon>mdi-rewind-30</v-icon>
        </v-btn>

        <!-- Loop Button -->
        <v-btn @click="toggleLoop" v-bind:disabled="isImage" :color="isLoopActive ? 'primary' : ''">
          <v-icon left>mdi-sync</v-icon>
        </v-btn>

        <!-- Autoplay Button -->
        <v-btn @click="toggleAutoplay" v-bind:disabled="isImage" :color="isAutoplayActive ? 'primary' : ''">
          <v-icon left>mdi-play-speed</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="close">Close</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['fileUrl', 'fileType', 'title'],
  data() {
    return {
      showModal: false,
      isLoopActive: false,
      isAutoplayActive: false
    }
  },
  computed: {
    isVideo() {
      return this.fileType === 'video';
    },
    isAudio() {
      return this.fileType === 'audio';
    },
    isImage() {
      return this.fileType === 'image';
    }
  },
  methods: {
    open() {
      this.showModal = true;
      this.$nextTick(() => {
        if (this.isVideo || this.isAudio) {
          this.$refs.mediaPlayer.play();
        }
      });
    },
    close() {
      this.showModal = false;
      if (this.isVideo || this.isAudio) {
        this.$refs.mediaPlayer.pause();
        this.$refs.mediaPlayer.currentTime = 0;
      }
    },
    previous() {
      // Emit the 'previous' event
      this.$emit('previous');
    },
    next() {
      // Emit the 'next' event
      this.$emit('next');
    },
    toggleLoop() {
      this.isLoopActive = !this.isLoopActive;
      if(this.isLoopActive) {
        this.isAutoplayActive = false;
      }
    },
    toggleAutoplay() {
      this.isAutoplayActive = !this.isAutoplayActive;
      if(this.isAutoplayActive) {
        this.isLoopActive = false;
      }
    },
    handleEnded() {
      if (this.isAutoplayActive) {
        this.$emit('next');
      }
    },
    skipForward() {
      if (this.isVideo || this.isAudio) {
        this.$refs.mediaPlayer.currentTime += 30;
      }
    },
    skipBackward() {
      if (this.isVideo || this.isAudio) {
        this.$refs.mediaPlayer.currentTime -= 30;
      }
    },
  }
}
</script>

<style scoped>
.v-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77vh;  /* Or a specific height based on your design */
  width: 100%;   /* Or a specific width based on your design */
}
.responsive-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
